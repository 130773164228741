/* src/Page.css */

/* 基本スタイル */
.page-content {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto; /* センターに配置 */
}
  
.content-section {
    display: flex;
    align-items: center;
    /* margin-bottom: 40px; */
}

.problem-section {
    background-color: --main-color;
}
  
.agitation-section {
    background-color: --main-color;
}
  
.solution-section {
    background-color: --main-color;
}
  
.offer-section {
    background-color: --main-color;
}
  
.narrowing-section {
    background-color: --main-color;
}
  
.action-section {
    background-color: --main-color;
}
  
.content-section .text {
    flex: 1;
    padding: 20px;
    line-height: 2.25rem;
    text-align: left; /* 左寄せ */
}
  
.content-section .image {
    flex: 1;
    padding: 20px;
}
  
.content-section .image img {
    width: 100%;
    border-radius: 10px;
}
  
/* 画像が右に来るスタイル */
.content-section.reverse {
    flex-direction: row-reverse;
}
  
/* レスポンシブ設定 - タブレット用 */
@media (max-width: 768px) and (min-width: 480px) {
    .page-content {
        padding: 15px;
    }

    .content-section {
        flex-direction: column;
        text-align: center;
    }
  
    .content-section.reverse {
        flex-direction: column;
    }
  
    .content-section .text,
    .content-section .image {
        padding: 15px;
        width: 100%;
    }
  
    /* 画像を16:9にクロップ */
    .content-section .image img {
        height: auto;
        max-height: calc(100vw * 9 / 16); /* 画面幅に対する16:9の高さを計算 */
        object-fit: cover; /* 画像をコンテナに収める */
        border-radius: 10px;
    }

    /* 個別の画像に対して object-position を設定 */
    .problem-image {
        object-position: center; /* 上部を中心にクロップ */
    }

    .agitation-image {
        object-position: top; /* 中央を中心にクロップ (デフォルト) */
    }

    .solution-image {
        object-position: center; /* 下部を中心にクロップ */
    }

    .offer-image {
        object-position: top; /* 下部を中心にクロップ */
    }

    
    .narrowing-image {
        object-position: center; /* 下部を中心にクロップ */
    }

    .action-image {
        object-position: center; /* 下部を中心にクロップ */
    }
}
  
/* レスポンシブ設定 - スマートフォン用 */
@media (max-width: 480px) {
    .page-content {
        padding: 10px;
    }

    .content-section {
        flex-direction: column;
        /* text-align: center; */
    }
  
    .content-section.reverse {
        flex-direction: column;
    }
  
    .content-section .text,
    .content-section .image {
        padding: 10px;
        width: 100%;
    }
  
    /* 画像を16:9にクロップ */
    .content-section .image img {
        width: 100%;
        height: auto;
        max-height: calc(100vw * 9 / 16); /* 画面幅に対する16:9の高さを計算 */
        object-fit: cover; /* 画像をコンテナに収める */
        border-radius: 10px;
    }

    /* 個別の画像に対して object-position を設定 */
    .problem-image {
        object-position: 50% 82%;
    }

    .agitation-image {
        object-position:  50% 40%;
    }

    .solution-image {
        object-position: 50% 70%;
    }

    .offer-image {
        object-position: 50% 45%;
    }

    
    .narrowing-image {
        object-position: 50% 45%; /* 下部を中心にクロップ */
    }

    .action-image {
        object-position: 50% 55%; /* 下部を中心にクロップ */
    }
}  