/* Consultation.css */

/* 全体のフォームスタイル */
form {
    display: flex;
    flex-direction: column;
    gap: 16px; /* フォーム要素間の間隔を調整 */
  }
  
  /* label 要素のスタイル */
  label {
    display: flex;
    flex-direction: column;
    gap: 4px; /* label 内のテキストと入力フィールド間の間隔を調整 */
  }
  
  /* input と textarea のスタイル */
  input,
  textarea {
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  textarea {
    resize: vertical; /* テキストエリアのサイズ変更を縦方向のみに制限 */
    min-height: 300px;
  }
  
  /* ボタンのスタイル */
  button {
    background-color: var(--main-color);
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: darken(var(--main-color), 10%);
  }
  