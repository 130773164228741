.footer {
  /* padding: 20px; */
  background-color: var(--main-color);
  color:var(--text-color-black);
  text-align: center;
}

.footer-menu {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row; /* デスクトップでは横並び */
  justify-content: center;
}

.footer-menu li {
  margin: 0 5px;
}

.footer-menu a {
  text-decoration: none;
  padding: 0;
  border-radius: 5px;
}

@media (max-width: 768px) {
  .footer-menu {
    flex-direction: column; /* モバイルでは縦並び */
  }

  .footer-menu li {
    margin: 5px 0;
  }
}
