/* src/App.css */
:root {
  --main-color: hsl(0 0% 100%);
  --accent-color: hsl(157.95 100% 22.94%);
  --text-color-light: hsl(0 0% 100%);
  --text-color-black: hsl(0 0% 0%);

  /* STARBUCKS GREEN hsl(159.8 100% 19.22%); */
  /* ACCENT GREEN hsl(157.95 100% 22.94%); */
  /* LIGHT GREEN hsl(160 32.31% 87.25%); */
  /* HOUSE GREEN hsl(164.44 31.03% 17.06%); */
  /* BLACK hsl(0 0% 0%); */
  /* WARM NEUTRAL hsl(42.86 21.21% 93.53%); */
  /* COOL NEUTRAL hsl(0 0% 97.65%) */
  /* WHITE hsl(0 0% 100%) */

}

body {
  background-color: var(--main-color);
  color: var(--text-color-black);
}

/* ページの基本スタイル */
.page-content {
  color: var(--text-color-black);
  font-family: Arial, sans-serif;
  /* padding: 20px; */
}

.navbar {
  background-color: var(--main-color);
  color: var(--text-color-black);
}

.footer {
  background-color: var(--main-color);
  color: var(--text-color-black);
}

/* ボタンやリンクなどのスタイル */
.btn, a {
  color: var(--text-color-black);
  /* background-color: var(--accent-color); */
  /* border-radius: 5px; 角を丸く */
}

.btn:hover, a:hover {
  color: var(--text-color-black);
  /* background-color: var(--accent-color); ホバー時に少し濃い青に */
}
