/* ナビゲーションバーのスタイル */
.navbar {
  background-color: var(--main-color);
  padding: 10px;
  display: flex;
  justify-content: space-between; /* 左端と右端に配置 */
  align-items: center;
  position: relative;
}

.navbar-brand {
  display: flex;
  align-items: center;
  /* 左端にブランド名を配置 */
}

.navbar-brand a {
  color: var(--text-color-black);
  text-decoration: none;
  font-size: 24px;
}

.navbar-toggler {
  background: none;
  border: none;
  cursor: pointer;
  display: none; /* デフォルトで非表示 */
}

.navbar-toggler-icon {
  width: 30px;
  height: 3px;
  background-color: var(--main-color);
  display: block;
  position: relative;
}

.navbar-toggler-icon::before,
.navbar-toggler-icon::after {
  content: '';
  width: 30px;
  height: 3px;
  background-color: var(--main-color);
  display: block;
  position: absolute;
  left: 0;
}

.navbar-toggler-icon::before {
  top: -8px;
}

.navbar-toggler-icon::after {
  top: 8px;
}

.navbar-collapse {
  display: flex;
  justify-content: flex-end; /* 右端にメニューを配置 */
}

.navbar-nav {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.navbar-nav li {
  margin-left: 20px;
}

.navbar-nav a {
  color: var(--text-color-black);
  text-decoration: none;
  font-size: 18px;
}

/* レスポンシブデザイン */
@media (max-width: 768px) {
  .navbar-toggler {
    display: block; /* スマートフォンやタブレットで表示 */
    color: var(--text-color-black);
  }

  .navbar-collapse {
    position: absolute;
    top: 60px;
    right: 0;
    width: 100%;
    background-color: var(--main-color);
    flex-direction: column;
    display: none;
  }

  .navbar-collapse.show {
    display: flex;
  }

  .navbar-nav {
    flex-direction: column;
    align-items: center;
  }

  .navbar-nav li {
    margin: 10px 0;
  }

  .navbar-nav a {
    display: block;
    padding: 10px;
    color: var(--text-color-black);
  }
}
